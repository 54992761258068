@import url('https://fonts.googleapis.com/css2?family=Anek+Devanagari:wght@700;800&family=Inter:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .button {
        @apply text-white font-medium text-sm rounded-lg hover:opacity-80;
        background: linear-gradient(180deg, #FF6B00 0%, #FF2E00 60.92%);
    }

    .action-button{
        @apply flex justify-center items-center h-8 w-8 rounded-full opacity-40 cursor-pointer hover:bg-black/5 hover:opacity-80 active:bg-black/10 active:opacity-100 transition-all duration-200;
    }

    .action-button__no-bg{
        @apply flex justify-center items-center h-8 w-8 rounded-full opacity-40 cursor-pointer hover:opacity-80 active:opacity-100 transition-all duration-200;
    }

    .h1{
        @apply font-serif text-4xl sm:text-6xl leading-none;
    }
}